/* Global Styles */
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./assets/fonts/Avenir.ttc) format("truetype");
  color:  #41C5F9;
}
* {
  font-family: Avenir, Arial, Helvetica, sans-serif;
}
h1 {
  color: #264D73;
  font-size: 2.5rem;
}
h2, h3 {
  color: #444;
  font-weight: lighter;
}
h3 {
  font-size: 1.3rem;
}
body {
  padding: .5rem;
  max-width: 400px;
  max-height: 300px;
  margin: auto;
  /*overflow-x: hidden;
  overflow-y: hidden;*/
}
@media (min-width: 500px) {
  body {
    padding: 2rem;
    /*overflow-x: hidden;
  overflow-y: hidden;*/
  }
}
body, input[text] {
  color: #333;
  font-family: Avenir, Georgia, serif;
}
a {
  cursor: pointer;
}
button {
  background-color: #41C5F9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
button:hover {
  background-color: #41C5F9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

button:active {
  background-color: #41C5F9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 1.2rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

button:disabled {
  background-color: #eee;
  color: #aaa;
  cursor: auto;
}


hr {
  margin: 1.5rem 0;
}
input[type="text"] {
  box-sizing: border-box;
  padding-right: 0.5rem;
  border-color: #41c5f9;
  height: 52px;
  width: 90px !important;
  border-radius: none;
  text-align: center;
}

input[type="text"]:focus { 
  box-sizing: border-box;
  padding-right: 0.5rem;
  border-color: #41c5f9;
  height: 52px;
  width: 90px !important;
  border-radius: none;
  text-align: center;
}

input[type="number"]:hover {
  box-sizing: border-box;
  padding-right: 0.5rem;
  border-color: #41c5f9;
  height: 52px;
  width: 90px !important;
  border-radius: none;
  text-align: center;
}

input[type="number"] {
  box-sizing: border-box;
  padding-right: 0.5rem;
  border-color: #41c5f9;
  height: 52px;
  width: 90px !important;
  border-radius: none;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

label{
  font-weight: bolder;
}

.img-thumbnail {
  border: none;
}


.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
  z-index: 2000;
}